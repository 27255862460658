import { makeAutoObservable } from "mobx";
import {IXProduct} from "../models/Product";

export default class LoyaltyStoreX {

  storeList: IXProduct[] = []
  storeSubsList: IXProduct[] = []
  bestList: IXProduct[] = []
  bestListIx: number = 0
  bestListLoading: boolean = false

  totalSale: number = 40

  storiesTab: string = "inview"

  bonuses: number = 0
  bonusCard: any | null = null
  bonusHistory: any[] = []

  isAccountLoaded: boolean = false

  visits: any[] = []

  loadingTimes: number = 1

  lastVisitDate: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  setLastVisitDate(val: string) {
    this.lastVisitDate = val
  }

  setLoadingTimes(times: number) {
    this.loadingTimes = times
  }

  setVisits(val: any[]) {
    this.visits = val
  }

  setIsAccountLoaded(val: boolean) {
    this.isAccountLoaded = val
  }

  setBonusCard(val: any) {
    this.bonusCard = val
  }

  setBonuses(val: number) {
    this.bonuses = val
  }

  setBonusHistory(val: any[]) {
    this.bonusHistory = val
  }

  setStoriesTab(val: string) {
    this.storiesTab = val
  }

  setStoreList(val: IXProduct[]) {
    this.storeList = val
  }

  setStoreSubsList(val: IXProduct[]) {
    this.storeSubsList = val
  }

  setBestList(val: IXProduct[]) {
    this.bestList = val
  }

  setBestListIx(val: number) {
    this.bestListIx = val
  }

  setBestListLoading(val: boolean) {
    this.bestListLoading = val
  }

}