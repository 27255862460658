import React from "react"

const Branches = React.lazy(() => import("../../pages/Branches/Branches"))
const BranchPage = React.lazy(() => import("../../pages/Branches/BranchPage"))
const Privacy = React.lazy(() => import("../../pages/Privacy"))
const NotFoundPage = React.lazy(() => import("../../pages/NotFoundPage"))

export interface IRoute {
  path: string
  component: React.ReactNode
  exact?: boolean
}

export enum RouteNames {
  P404 = '*',
  HOME = '/',
  HOME_2 = '/barbershop',
  HOME_3 = '/barbershop/:name',

  BRANCHES = '/branches',
  BRANCHE_PAGE = '/branches/:name',
  BRANCHE_PAGE_RG = '/branches/rg/:rid',
  BRANCHE_RG_PAGE = '/branches/:rid/:id',

  B_BRANCHES = '/b',
  B_BRANCHE_PAGE = '/b/:name',
  B_BRANCHE_PAGE_RG = '/b/rg/:rid',
  B_BRANCHE_RG_PAGE = '/b/:rid/:id',

  BEBARBER = '/bebarber',
  PRIVACY = '/privacy'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.P404, exact: true, component: <NotFoundPage /> },
  { path: RouteNames.HOME, exact: true, component: <Branches /> },
  { path: RouteNames.HOME_2, exact: true, component: <Branches /> },
  { path: RouteNames.HOME_3, exact: true, component: <BranchPage /> },

  { path: RouteNames.BRANCHES, exact: true, component: <Branches /> },
  { path: RouteNames.BRANCHE_PAGE, exact: true, component: <BranchPage /> },
  { path: RouteNames.BRANCHE_PAGE_RG, exact: true, component: <BranchPage /> },
  { path: RouteNames.BRANCHE_RG_PAGE, exact: true, component: <BranchPage /> },

  { path: RouteNames.B_BRANCHES, exact: true, component: <Branches /> },
  { path: RouteNames.B_BRANCHE_PAGE, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BRANCHE_PAGE_RG, exact: true, component: <BranchPage /> },
  { path: RouteNames.B_BRANCHE_RG_PAGE, exact: true, component: <BranchPage /> },

  { path: RouteNames.PRIVACY, exact: true, component: <Privacy /> }
]

export const twRoutes: IRoute[] = []
