import { observer } from "mobx-react-lite"
import { FC, useContext } from "react"
import {Context} from "..";

const GlobalFader: FC = () => {
  const { store } = useContext(Context);
  return (
    <>
      <div 
        className="fader" 
        style={{
          display: store.isFaderVisible ? "block" : "none", 
          opacity: store.isFaderShown ? "1" : "0"
        }}>
        <div><img style={{
          opacity: store.isFaderLogoVisible ? "1" : "0"
        }} src="/assets/images/logo.svg" /></div>
      </div>
    </>
  )
}

export default observer(GlobalFader)