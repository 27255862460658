import React, {createContext} from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Store from './core/store'
import { BrowserRouter } from 'react-router-dom'
import LoyaltyStoreX from './core/store/loyalty'
import AdminStore from './core/store/admin'

interface State {
  store: Store
  adminStore: AdminStore
  loyaltyStore: LoyaltyStoreX
}

const store = new Store()
const adminStore = new AdminStore()
const loyaltyStore = new LoyaltyStoreX()

export const Context = createContext<State>({ store, loyaltyStore, adminStore })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Context.Provider value={{ store, loyaltyStore, adminStore }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
);

reportWebVitals();
